<template>
  <div>
    <div class="main-hero-wrap">
      <header>
        <div class="container">
          <div class="logo">
            <a href="#">
              <img src="../../assets/logoipsum.svg" alt="" />
            </a>
          </div>
          <nav>
            <ul>
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <div class="c-badge">Sale</div>
                <a href="#">Pricing</a>
              </li>
              <li>
                <a href="#">Support</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Contact</a>
              </li>
            </ul>
          </nav>
          <div class="header-buttons">
            <router-link to="/login" class="basket">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="currentColor"
                class="bi bi-person"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
                />
              </svg>
              User Panel
            </router-link>
            <button class="hamburger-btn">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5 4.375H2.5C2.15833 4.375 1.875 4.09167 1.875 3.75C1.875 3.40833 2.15833 3.125 2.5 3.125H17.5C17.8417 3.125 18.125 3.40833 18.125 3.75C18.125 4.09167 17.8417 4.375 17.5 4.375Z"
                  fill="white"
                />
                <path
                  d="M17.4999 8.54175H9.60828C9.26661 8.54175 8.98328 8.25841 8.98328 7.91675C8.98328 7.57508 9.26661 7.29175 9.60828 7.29175H17.4999C17.8416 7.29175 18.1249 7.57508 18.1249 7.91675C18.1249 8.25841 17.8416 8.54175 17.4999 8.54175Z"
                  fill="white"
                />
                <path
                  d="M17.5 12.7083H2.5C2.15833 12.7083 1.875 12.4249 1.875 12.0833C1.875 11.7416 2.15833 11.4583 2.5 11.4583H17.5C17.8417 11.4583 18.125 11.7416 18.125 12.0833C18.125 12.4249 17.8417 12.7083 17.5 12.7083Z"
                  fill="white"
                />
                <path
                  d="M17.4999 16.875H9.60828C9.26661 16.875 8.98328 16.5917 8.98328 16.25C8.98328 15.9083 9.26661 15.625 9.60828 15.625H17.4999C17.8416 15.625 18.1249 15.9083 18.1249 16.25C18.1249 16.5917 17.8416 16.875 17.4999 16.875Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </header>

      <div class="page-hero-wrapper">
        <div class="container">
          <div class="hero-slider-item">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="hero-text">
                  <div class="offer">
                    <span>Offer</span>
                    Lorem ipsum dolor
                  </div>
                  <h1>
                    Best Shipping Service <br />
                    to Save Money
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Necessitatibus expedita, eveniet quaerat esse voluptates dolorem
                    delectus est natus aliquid sequi dolorum fuga, at error.
                  </p>
                  <div class="actions">
                    <a href="#">
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5833 8.94629C16.0508 8.41504 15.6754 7.58629 14.9671 7.58629H12.7784V2.48629C12.7784 1.73546 11.8433 1.38129 11.3475 1.94796L5.98544 8.03963C5.51794 8.57088 5.89335 9.39963 6.60169 9.39963H8.79043V14.4996C8.79043 15.2505 9.72543 15.6046 10.2213 15.038L13.5008 11.3121"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.02083 2.83325H1.0625"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.3125 14.1667H1.0625"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3.1875 8.5H1.0625"
                          stroke="white"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Get Started Now
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="hero-image">
                  <img src="../../assets/hero01.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantages-wrapper">
      <div class="container">
        <div class="section-title">
          <h3>Our Adventages</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </p>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="adv-item">
              <div class="head">
                <span
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    class="bi bi-lightning-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"
                    />
                  </svg>
                </span>
                <strong>Easy Setup Process</strong>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam totam odio
                corrupti perferendis nesciunt quisquam, porro unde.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="support-wrapper left-pattern-bg">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="content">
              <h3>
                Cheapest Shipping <br />
                Services
              </h3>
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever since the
                1500s, when an unknown printer took a galley of type and scrambled.
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="image">
              <img src="../../assets/support-img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="testimonials">
      <div class="container">
        <div class="section-title">
          <h3>Testimonials</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </p>
        </div>
        <div class="testi-slider">
          <div class="testi-item">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna.
            </p>
            <div class="stars">
              <img src="../../assets/stars.svg" alt="" />
            </div>
            <div class="person">
              <img src="../../assets/person.png" alt="" />
              <strong>- Aligabith Ann Lana</strong>
              <span>Lorem Ceo</span>
            </div>
          </div>
          <div class="testi-item">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna.
            </p>
            <div class="stars">
              <img src="../../assets/stars.svg" alt="" />
            </div>
            <div class="person">
              <img src="../../assets/person.png" alt="" />
              <strong>- Aligabith Ann Lana</strong>
              <span>Lorem Ceo</span>
            </div>
          </div>
          <div class="testi-item">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna.
            </p>
            <div class="stars">
              <img src="../../assets/stars.svg" alt="" />
            </div>
            <div class="person">
              <img src="../../assets/person.png" alt="" />
              <strong>- Aligabith Ann Lana</strong>
              <span>Lorem Ceo</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="newsletter">
      <div class="container">
        <div class="nl-box">
          <div class="text">
            <h3>Try it out now!</h3>
            <p>Start Shipping!</p>
          </div>
          <div class="buttons">
            <a href="#"> Get Started </a>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="footer-info">
              <img src="../../assets/logoipsum.svg" alt="" />
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
          </div>
          <div class="col-md-9">
            <div class="row">
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Company</strong>
                  <a href="#">Blog</a>
                  <a href="#">Help Center</a>
                  <a href="#">Sales Tools Catalog</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Support </strong>
                  <a href="#">Partners</a>
                  <a href="#">Policy</a>
                  <a href="#">Programs</a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="footer-links">
                  <strong>Get in touch</strong>
                  <a href="#">Support</a>
                  <a href="#">About us</a>
                  <a href="#">FAQ</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <p>© 2023 All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style></style>
